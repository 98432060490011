import { useEffect, useRef, useState } from "react";
import useAppContext from "../hooks/useAppContext";
import ListaRequisitos from "./ListaRequisitos";

const InputFile = ({ setStatusArchivos }) => {
  //* CONTEXTAPP
  const {
    listaRequisitos,
    archivosAdjuntos,
    setArchivosAdjuntos,
    listaNombreArchivos,
    setListaNombreArchivos,
    nombreArchivos,
    setNombresArchivos,
  } = useAppContext();

  //* VARIABLES DE ESTADO
  const [alerta, setAlerta] = useState(false);

  //* CONSTANTES
  const PESO_MAXIMO = 20971520;
  const inputFile = useRef();

  //* RESET INPUT FILE
  const resetInput = () => {
    inputFile.current.value = null;
  };

  let pesoArchivos;
  useEffect(() => {
    pesoArchivos = archivosAdjuntos
      .map((file) => file.size)
      .reduce((prev, curr) => prev + curr, 0);
    if (pesoArchivos >= PESO_MAXIMO) {
      setAlerta({
        msg: "Se han superado los 20MB permitidos, por favor revisar el/los archivo(s) a adjuntar.",
        error: true,
      });
      setStatusArchivos(true);
      return;
    } else {
      setAlerta({});
      setStatusArchivos(false);
    }
  }, [archivosAdjuntos]);

  const subirArchivos = (file) => {
    const files = file.target.files;
    let archivos = [];
    for (const i of files) {
      const file = i;
      const nombreArchivo = file.name.replace(/[^a-zA-Z0-9\_\-\.\s]/g, "");
      const tipoArchivo = i.type;
      const blod = file.slice(0, file.size, tipoArchivo);
      const archivoFinal = new File([blod], nombreArchivo, {
        type: tipoArchivo,
      });
      archivos.push(archivoFinal);
    }
    if (nombreArchivos?.includes(archivos[0].name)) {
      setAlerta({
        msg: "El archivo seleccionado ya se encuentra en la lista de adjuntos",
        error: true,
      });
      resetInput();
      return;
    }
    const extArchivo = archivos[0].name.split(".");
    const ext = extArchivo[extArchivo.length - 1];
    //console.log(ext);
    if (
      ext !== "pdf" &&
      ext !== "doc" &&
      ext !== "docx" &&
      ext !== "jpg" &&
      ext !== "jpeg" &&
      ext !== "png"
    ) {
      setAlerta({
        msg: "Formato de archivo no permitido.",
        error: true,
      });
      resetInput();
      return;
    }
    for (const j of archivos) {
      setArchivosAdjuntos((prevFile) => [...prevFile, j]);
      // setArchivosAdjuntos([...archivosAdjuntos, j]);
      setAlerta({});
    }
    for (const i of archivos) {
      const documento = {
        nombreArchivo: i.name,
        descripcion: "",
        path: null,
        extension: ext,
      };
      setListaNombreArchivos((listaNombreArchivos) => [
        ...listaNombreArchivos,
        documento,
      ]);
    }

    
  };
  useEffect(() => {
    archivosAdjuntos.map((file) => {
      setNombresArchivos((prevFile) => [...prevFile, file.name]);
    });
    resetInput();
  }, [archivosAdjuntos]);

  const eliminarArchivo = (archivoEliminar) => {
    const archivoEliminado = archivosAdjuntos.filter(
      (archivo) => archivo.name !== archivoEliminar
    );
    // console.log(archivoEliminado);
    setArchivosAdjuntos(archivoEliminado);
    setListaNombreArchivos(archivoEliminado);
    setNombresArchivos(archivoEliminado);
  };

  const { msg } = alerta;

  return (
    <>
      {listaRequisitos.listado && (
        <ListaRequisitos listado={listaRequisitos.listado} />
      )}

      <div className="row mb-4">
        <label
          className="col-sm-12 col-md-12 col-lg-3 form-label text-capitalize fw-bold"
          htmlFor="inputFile"
        >
          Subir Archivos
        </label>
        <div className="col-sm-12 col-md-12 col-lg-6 border shadow-sm p-4">
          <input
            ref={inputFile}
            type="file"
            id="subirArchivo"
            className="form-control shadow-sm mb-2"
            name="documents"
            // multiple="multiple"
            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,"
            onChange={subirArchivos}
          />
          <p className=" m-0">
            Si el nombre del archivo cuenta con caracteres especiales no
            permitidos, los mismos serán reemplazados para evitar problemas en
            la carga de archivos. <br />
            Formatos permitidos:{" "}
            <span className="fw-normal">.pdf,.doc,.docx,.jpg,.jpeg,.png</span>
            <br />
            <span className="fw-bold">Peso máx. permitido 20MB</span>
          </p>
          {msg && <Errores alerta={alerta} />}
          {archivosAdjuntos.map((ele) => (
            <div className="input-group my-3" key={ele.name}>
              <input
                className="form-control"
                type="text"
                value={ele.name}
                disabled
              />
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => eliminarArchivo(ele.name)}
              >
                X
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const Errores = ({ alerta }) => {
  return (
    <>
      <p className="text-danger text-center fw-bold py-1 my-2">{alerta.msg}</p>
    </>
  );
};

export default InputFile;

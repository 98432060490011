import { createContext, useEffect, useState } from "react";
import { fetchApi, fetchCargarArchivo } from "../helpers/fetchApi";
import { useNavigate, useLocation } from "react-router-dom";

const AppContext = createContext();

console.log(process.env.REACT_APP_WS_SOLICITUDES_CIUDAD_LISTAR_TIPO_USUARIO);


const AppProvaider = ({ children }) => {
  const [modoSites, setModoSites] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tipoUsuario, setTipoUsuario] = useState("");
  const [tipoSolicitud, setTipoSolicitud] = useState("");
  const [tipificacion, setTipificacion] = useState("");
  const [tipoTipificacion, setTipoTipificacion] = useState("");
  const [listaRequisitos, setListaRequisitos] = useState([]);
  const [listaRegiones, setListaRegiones] = useState([]);
  const [listaComunas, setListaComunas] = useState([]);
  const [listaTipoIdentificacion, setListaTipoIdentificacion] = useState([]);
  const [listaGenero, setListaGenero] = useState([]);
  const [listaNacionalidad, setListaNacionalidad] = useState([]);
  const [datosPersona, setDatosPersona] = useState({});
  const [desplegarRquisitos, setDesplegarRequisitos] = useState(0);
  const [archivosAdjuntos, setArchivosAdjuntos] = useState([]);
  const [listaNombreArchivos, setListaNombreArchivos] = useState([]);
  const [nombreArchivos, setNombresArchivos] = useState([]);
  const [objTramites, setObjTramites] = useState({
    codTipoTipificacion: "",
    descripcion: "",
  });
  const [confirmacionTramite, setConfirmacionTramite] = useState({
    folio: "",
    correo: "",
  });
  const [identificacion, setIdentificacion] = useState("");
  const [tipoIdentificaion, setTipoIdentificacion] = useState("");
  const [datosFormUsuario, setDatosFormUsuario] = useState({
    numeroIdentificacion: "",
    nacionalidad: "",
    nombre: "",
    apellidos: "",
    genero: "",
    fechaNacimiento: "",
    region: "",
    comuna: "",
    direccion: "",
    telefono1: "",
    telefono2: "",
    telefonoFijo: "",
    email: "",
  });

  let objRequest = {};

  //* CONSTANTES
  const SITES = "/sites/fonasa/ahorratefilaprueba";
  const BENEFICIARIO = 1;
  const TIPO_SOLICITUD = 4;
  const ATENCION_QR = 137;
  let PATH_ACTUAL = window.location.pathname;
  // let PATH_ACTUAL = useLocation();
  // console.log("CON WINDOW.PARENT.LOCATION", PATH_ACTUAL);

  useEffect(() => {
    if (PATH_ACTUAL === SITES) {
      setModoSites(true);
      console.log(PATH_ACTUAL);
      console.log("SE EJECUTA");
    }
  }, [PATH_ACTUAL.pathname]);

  const getTipoTipificacionAux = (data, id) => {
    const des = data?.find((desc) => desc.idDetalleSolicitud === id);
    return des;
  };
  objRequest.tipoTipificacionAux = getTipoTipificacionAux(
    tipoTipificacion.listado,
    objTramites.codTipoTipificacion * 1
  );
  objRequest.tipoUsuario = BENEFICIARIO;
  objRequest.tipoSolicitud = TIPO_SOLICITUD;
  objRequest.tipificacion = ATENCION_QR;
  objRequest.tipoTipificacion = objTramites.codTipoTipificacion * 1;
  objRequest.descripcionSolicitud =
    objTramites.descripcion !== ""
      ? objTramites.descripcion
      : objRequest.tipoTipificacionAux?.descripcion;

  //* VERIFICAR PATH DE DONDE SE ESTA ACCEDIENDO
  const validarPath = () => {
    // if(PATH_ACTUAL.pathname === SITES){
    //   setModoSites(true)
    //   console.log(PATH_ACTUAL);
    //   console.log('SE EJECUTA');
    // }
  };
  useEffect(() => {
    setArchivosAdjuntos([]);
    setListaNombreArchivos([]);
    setNombresArchivos([]);
    setObjTramites({ ...objTramites, descripcion: "" });
  }, [objTramites.codTipoTipificacion]);

  //* REINICIAR APP
  const navigate = useNavigate();
  const reiniciarApp = () => {
    setDatosFormUsuario({});
    setDatosPersona({});
    setObjTramites({});
    setConfirmacionTramite({});
    setIdentificacion("");
    setTipoIdentificacion("");
    setDesplegarRequisitos(0);
    setArchivosAdjuntos([]);
    setListaNombreArchivos([]);
    navigate("/");
    window.location.reload();
  };
  //* LISTAR TIPO USUARIO
  const getTipoUsuario = async () => {
    const URL = process.env.REACT_APP_WS_SOLICITUDES_CIUDAD_LISTAR_TIPO_USUARIO;
    const getFetch = await fetchApi(URL);
    if (getFetch.codigo === 0 && getFetch.listado) {
      const beneficiario = getFetch.listado.filter(
        (opcion) => opcion.idTipoUsuario === BENEFICIARIO
      );
      setTipoUsuario(beneficiario[0]);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (tipoUsuario) {
      getTipoSolicitud(tipoUsuario.idTipoUsuario);
    }
  }, [tipoUsuario]);

  useEffect(() => {
    if (tipoSolicitud) {
      getListarTipificacion(tipoSolicitud.idTipoSolicitud);
    }
  }, [tipoSolicitud]);

  useEffect(() => {
    if (tipificacion) {
      getListarTipoTipificacion(tipificacion.idSubTipoSolicitud);
    }
  }, [tipificacion]);

  //* LISTAR TIPO SOLICITUD
  const getTipoSolicitud = async (tipoUsuario) => {
    const objRequest = {
      tipoUsuario: tipoUsuario,
    };
    const URL = process.env.REACT_APP_WS_SOLICITUDES_CIUDAD_LISTAR_TIPO_SOLICITUD;
    const getFecthData = await fetchApi(URL, objRequest);

    if (getFecthData.codigo === 0) {
      const tipoSolic = getFecthData.listado.filter(
        (opcion) => opcion.idTipoSolicitud === TIPO_SOLICITUD
      );
      setTipoSolicitud(tipoSolic[0]);
      setIsLoading(false);
    }
  };

  //* LISTAR TIPIFICACION
  const getListarTipificacion = async (codtipoSolicitud) => {
    const objRequest = { idTipoSolicitud: codtipoSolicitud };
    const URL = process.env.REACT_APP_WS_SOLICITUDES_CIUDAD_LISTAR_TIPIFICACION;
    const getFecthData = await fetchApi(URL, objRequest);
    if (getFecthData.codigo === 0) {
      const tipificac = getFecthData.listado.filter(
        (opcion) => opcion.idSubTipoSolicitud === ATENCION_QR
      );
      setTipificacion(tipificac[0]);
      setIsLoading(false);
    }
  };

  //* LISTAR TIPO TIPIFICACION
  const getListarTipoTipificacion = async (codTipificacion) => {
    const objRequest = { idSubTipoSolicitud: codTipificacion };
    const URL = process.env.REACT_APP_WS_SOLICITUDES_CIUDAD_LISTAR_TIPO_TIPIFI;
    const getFecthData = await fetchApi(URL, objRequest);
    if (getFecthData.codigo === 0) {
      setTipoTipificacion(getFecthData);
      setIsLoading(false);
    }
  };

  //* LISTAR REQUISITOS
  const getListarRequisitos = async (codTipoTipificacion) => {
    const objRequest = { idDetalleSolicitud: codTipoTipificacion };
    setIsLoading(true);
    const URL = process.env.REACT_APP_WS_SOLICITUDES_CIUDAD_LISTAR_REQUISITOS_SOLIC;
    const getFecthData = await fetchApi(URL, objRequest);
    if (getFecthData.codigo === 0) {
      setListaRequisitos(getFecthData);
      setDesplegarRequisitos(1);
      setIsLoading(false);
    }
  };

  //* ENVIO CORREO REQUISITOS
  const envioCorreoRquisitos = async (correo) => {
    const objRequest = {
      correo: correo,
      TipoSolicitud: objTramites.codTipoTipificacion * 1,
    };
    const URL = process.env.REACT_APP_WS_SOLICITUDES_CIUDAD_ENVIO_CORREO_REQUISITOS;
    setIsLoading(true);
    const getFecthData = await fetchApi(URL, objRequest);
    if (getFecthData.codigo === 0) {
      setIsLoading(false);
      return getFecthData;
    }
  };

  //* CONSULTA NOMBRE PERSONA
  const getNombreRut = async (rut) => {
    // console.log(rut)
    const request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apikey: process.env.REACT_APP_APY_KEY,
      },
      body: JSON.stringify(rut),
    };
    const URL = process.env.REACT_APP_WS_SOLICITUDES_CIUDAD_CONSULTA_NOMBRES;
    setIsLoading(true);
    const resp = await fetch(URL, request);
    const data = await resp.json();
    setDatosPersona(data);
    setIsLoading(false);
    return data;
  };

  //* LISTAR REGIONES
  const getListarRegiones = async () => {
    const cuerpo = {
      headerRequest: {
        userID: "",
        rolID: "",
        SucursalID: "",
        fechaHora: "",
      },
      bodyRequest: {
        codigoTipoColeccion: 101,
        codigoItemPadre: "",
      },
    };
    const request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apikey: process.env.REACT_APP_APY_KEY,
      },
      body: JSON.stringify(cuerpo),
    };
    const URL = process.env.REACT_APP_WS_SOLICITUDES_CIUDAD_SIIS_COLECCIONES;
    const resp = await fetch(URL, request);
    const data = await resp.json();
    setListaRegiones(data.bodyResponse.coleccion.item);
  };

  //* LISTAR COMUNAS
  const getListarComunas = async (codigo) => {
    const cuerpo = {
      headerRequest: {
        userID: "",
        rolID: "",
        SucursalID: "",
        fechaHora: "",
      },
      bodyRequest: {
        codigoTipoColeccion: 103,
        codigoItemPadre: codigo,
      },
    };
    const request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apikey: process.env.REACT_APP_APY_KEY,
      },
      body: JSON.stringify(cuerpo),
    };
    const URL = process.env.REACT_APP_WS_SOLICITUDES_CIUDAD_SIIS_COLECCIONES;
    setIsLoading(true);

    const resp = await fetch(URL, request);
    const data = await resp.json();
    setListaComunas(data.bodyResponse.coleccion.item);
    setIsLoading(false);
  };
  //* LISTAR GENERO
  const getListarGenero = async () => {
    const cuerpo = {
      headerRequest: {
        userID: "",
        rolID: "",
        SucursalID: "",
        fechaHora: "",
      },
      bodyRequest: {
        codigoTipoColeccion: 4,
        codigoItemPadre: "",
      },
    };
    const request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apikey: process.env.REACT_APP_APY_KEY,
      },
      body: JSON.stringify(cuerpo),
    };
    const URL = process.env.REACT_APP_WS_SOLICITUDES_CIUDAD_SIIS_COLECCIONES;
    const resp = await fetch(URL, request);
    const data = await resp.json();
    setListaGenero(data.bodyResponse.coleccion.item);
  };

  //* LISTAR NACIONALIDAD
  const getListaNacionalidad = async () => {
    const cuerpo = {
      headerRequest: {
        userID: "",
        rolID: "",
        SucursalID: "",
        fechaHora: "",
      },
      bodyRequest: {
        codigoTipoColeccion: 7,
        codigoItemPadre: "",
      },
    };
    const request = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        apikey: process.env.REACT_APP_APY_KEY,
      },
      body: JSON.stringify(cuerpo),
    };
    const URL = process.env.REACT_APP_WS_SOLICITUDES_CIUDAD_SIIS_COLECCIONES;
    const resp = await fetch(URL, request);
    const data = await resp.json();
    setListaNacionalidad(data.bodyResponse.coleccion.item);
  };

  //* LISTAR TIPO IDENTIFICACION
  const getTipoIdentificacion = async () => {
    const URL = process.env.REACT_APP_WS_SOLICITUDES_CIUDAD_LISTAR_TIPO_IDENTIFICACION;
    const getFecthData = await fetchApi(URL);
    if (getFecthData.codigo === 0 && getFecthData.listado) {
      setListaTipoIdentificacion(getFecthData.listado);
      setIsLoading(false);
    }
  };

  //* CARGA DE ARCHIVOS
  let listaFisicosArchivos = new FormData();
  const cargarArchivo = async (pathArchivos) => {
    const URL = process.env.REACT_APP_WS_SOLICITUDES_CIUDAD_ADJUNTAR_ARCHIVOS;
    setIsLoading(true);
    const pathFolio = pathArchivos;
    for (const i of archivosAdjuntos) {
      listaFisicosArchivos.path = pathArchivos;
      listaFisicosArchivos.append("uploadingFiles", i);
      // console.log(listaFisicosArchivos);
    }
    listaFisicosArchivos.append("path", pathFolio);
    const getFecthData = await fetchCargarArchivo(URL, listaFisicosArchivos);
    if (getFecthData.codigo === 0) {
      setIsLoading(false);
    }
    return getFecthData;
  };

  //* CONFIRMAR SOLICITUD
  const confirmarSolicitud = async (numeroSolicitud) => {
    const URL = process.env.REACT_APP_WS_SOLICITUDES_CIUDAD_CONFIRMAR_SOLICITUD;
    //*AGREGAR PATH A LOS ARICHIVOS
    for (const i of listaNombreArchivos) {
      i.path = `${numeroSolicitud}/1`;
    }
    // console.log(listaNombreArchivos);
    const objRequest = {
      numeroSolicitud,
      documentoAdjunto: listaNombreArchivos,
    };
    let estadoProceso = 1;
    const getFecthData = await fetchApi(URL, objRequest);
    return getFecthData;
  };

  const getDescripcionRegion = (data, id) => {
    const des = data?.find((desc) => desc.codigoPrincipal === id);
    return des.descripcion;
  };
  const getDescripcionComuna = (data, id) => {
    const des = data?.find((desc) => desc.codigoPrincipal === id);
    return des.descripcion;
  };

  useEffect(() => {
    getTipoUsuario();
    getTipoIdentificacion();
    getListarRegiones();
    getListarGenero();
    getListaNacionalidad();
    validarPath();
  }, []);

  return (
    <AppContext.Provider
      value={{
        getTipoSolicitud,
        getListarTipificacion,
        getListarTipoTipificacion,
        getListarRequisitos,
        getListarComunas,
        getNombreRut,
        tipoUsuario,
        tipoSolicitud,
        tipificacion,
        tipoTipificacion,
        listaRequisitos,
        listaRegiones,
        listaComunas,
        listaTipoIdentificacion,
        listaGenero,
        listaNacionalidad,
        desplegarRquisitos,
        datosPersona,
        isLoading,
        objTramites,
        objRequest,
        envioCorreoRquisitos,
        getDescripcionRegion,
        getDescripcionComuna,
        setObjTramites,
        archivosAdjuntos,
        setArchivosAdjuntos,
        cargarArchivo,
        listaNombreArchivos,
        setListaNombreArchivos,
        nombreArchivos,
        setNombresArchivos,
        confirmarSolicitud,
        setIsLoading,
        confirmacionTramite,
        setConfirmacionTramite,
        reiniciarApp,
        modoSites,
        datosFormUsuario,
        setDatosFormUsuario,
        setDatosPersona,
        identificacion,
        setIdentificacion,
        tipoIdentificaion,
        setTipoIdentificacion,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppProvaider };

export default AppContext;

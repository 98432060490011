import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Banner from "../components/Banner";
import ContactoRRSS from "../components/ContactoRRSS";
import Spinner from "../components/Spinner";
import useAppContext from "../hooks/useAppContext";

const Layout = () => {
  const { isLoading, modoSites } = useAppContext();
  return (
    <>
      <div className="mb-3">
        {isLoading && <Spinner />}
        <Banner />
        <Outlet />
      </div>
    </>
  );
};

export default Layout;

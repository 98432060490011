import { setMayusculas } from "../helpers/validadores";

const InputText = ({
  type,
  clase,
  value,
  label,
  name,
  id,
  htmlFor,
  onChange,
  errorsMessage,
  disabled,
  maxLength,
  obligatorio,
  placeholder,
}) => {
  return (
    <>
      <div className="col-12 col-sm-12  d-flex flex-column flex-md-row  pe-4 mb-3">
        <label
          className="form-label col-sm-5 col-md-6 col-lg-5 fw-bold"
          htmlFor={htmlFor}
        >
          {label}: {obligatorio ? <span className="text-danger">*</span> : null}
        </label>
        <div className="col-sm-12 col-md-6 col-lg-7">
          <input
            type={type}
            className={clase}
            name={name}
            id={id}
            disabled={disabled}
            maxLength={maxLength}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
          {errorsMessage && (
            <div className="mt-1">
            <span className="text-danger">{errorsMessage}</span>
          </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InputText;

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppProvaider } from "./context/AppProvaider";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./layout/Layout";
import Tramites from "./pages/Tramites";
import TramiteFinalizado from "./pages/TramiteFinalizado";
import RutaProtegida from "./layout/RutaProtegida";
import FormDatosUser from "./pages/FormDatosUser";

function App() {
  return (
    <div className="">
      <BrowserRouter>
        <AppProvaider>
          <Routes>
            {/* Rutas Publicas */}
            <Route path="/" element={<Layout />}>
              <Route index element={<Tramites />} />
              
            </Route>

            <Route path="/form-datos-usuario" element={<RutaProtegida />}>
              <Route index element={<FormDatosUser />} />
              <Route path="tramite-finalizado" element={<TramiteFinalizado/>}/>
            </Route>
            
          </Routes>
        </AppProvaider>
      </BrowserRouter>
    </div>
  );
}

export default App;

import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AlertaWarning from "./AlertaWarning";

const ModalConfirmar = ({
  show,
  handleClose,
  handleShow,
  modalConfirmar,
  casosAnteriores,
}) => {
  const { listado, totalRegistros } = casosAnteriores;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This adds a smooth scrolling effect
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Envío de Solicitud</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-1 mx-sm-5">
          <div className="alert alert-warning my-2 p-4">
            <div className="col d-flex  align-items-center" role="alert">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                viewBox="0 0 16 16"
                role="img"
                aria-label="warning:"
              >
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>

              <h4 className="m-0 fw-bold">
                ¿Está seguro de enviar la solicitud ingresada?
              </h4>
            </div>
            {casosAnteriores.listado ? (
              <div className="col m-3">
                <p className="m-0 text-body">
                  En la última hora se ha(n) encontrado {totalRegistros}{" "}
                  registro(s) creado(s) por {listado[0].usuariocreacion} a través del canal {listado[0].canal} de tipo: {listado[0].tipotificacion}.
                </p>
                <p className="my-2 text-body">Caso(s) asociados:</p>
                <ul>
                    {listado.map(folio => (
                        <li key={folio.solicitud}>{folio.solicitud}</li>
                    ))}
                </ul>
                <p className="my-2 text-body">
                  Favor validar su bandeja de correo antes de continuar.
                </p>
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={modalConfirmar}>
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalConfirmar;

// ¿Está seguro de enviar la solicitud ingresada?

import React from "react";
import { Link } from "react-router-dom";
import useAppContext from "../hooks/useAppContext";
import ProgressBar from "../components/ProgressBar";

const TramiteFinalizado = () => {
  const { confirmacionTramite, reiniciarApp } = useAppContext()
  const {correo, folio} = confirmacionTramite;

  const steps = ["Tipo Solicitud", "Datos Solicitante y Afectado", "Comprobante de envío"];
  const currentStep = 2;
  return (
    <div className="container">
      <ProgressBar steps={steps} currentStep={currentStep}/>
      
      <hr />
      {/* Mensaje proceso completado */}
      <div className="row justify-content-center mt-5">
        <div className="col col-md-8 col-lg-5">
          <div className="alert alert-success py-5 shadow" role="alert">
            <h5 className="text-center mb-3">Su <span className="fw-bold">solicitud</span> ha sido procesada con éxito</h5>
            <h5 className="text-center m-0">Bajo el folio <span className="fw-bold">N° {folio}</span></h5>
          </div>
          <p className="text-center">Se le notificará a <span className="fw-bold">{correo}</span> cuando se de respuesta a su solicitud, se sugiere revisar bandeja de spam o correos no deseado.</p>
        </div>
      </div>

      <div className="row justify-content-center mt-5">
        <div className="col text-center mb-4">
          <button onClick={reiniciarApp} className="btn btn-secondary me-2 shadow-sm">
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default TramiteFinalizado;

import { useState } from "react";
import useAppContext from "../hooks/useAppContext";

const ListaRequisitos = ({ listado }) => {
  const [requisitos, setRequisitos] = useState(false);
  const [emailRequisitos, setEmailRequisitos] = useState("");
  const [alerta, setAlerta] = useState(false);
  const [correoValido, setCorreoValido] = useState(true);

  const { envioCorreoRquisitos } = useAppContext();

  const TIPO = "formulario";

  const adjuntos = listado.filter((adjunto) => adjunto.tipo !== TIPO);

  const handleChangeEmail = ({ target }) => {
    setEmailRequisitos(target.value);
    if (
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(target.value)
    ) {
      setAlerta({
        msg: "El correo ingesado no es válido",
        error: true,
      });
      setCorreoValido(true)
      return;
    }
    setCorreoValido(false);
    setAlerta({});
  };

  const enviarCorreo = async () => {
    const data = await envioCorreoRquisitos(emailRequisitos);
    if(data.codigo === 0 && data.listadoRequisitos){
      setAlerta({
        msg: "Correo de requisitos enviado satisfactoriamente.",
        error: false,
      });
      setEmailRequisitos('')
      setCorreoValido(true)
      setTimeout(()=> {
        setAlerta('')
      }, 3000)
    }else{
      setAlerta({
        msg: "Correo no puso ser enviado. Por favor intente más tarde",
        error: true,
      });
    }
  };

  const { msg } = alerta;

  return (
    <>
      <div className="row justify-content-center my-2">
        <div
          className="msg-asesor shadow col-sm-12 col-md-12 col-lg-6 d-flex align-items-center"
          role="alert"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-info-circle-fill flex-shrink-0 me-2"
            viewBox="0 0 16 16"
            role="img"
            aria-label="Primary:"
          >
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
          </svg>

          <p className="m-0">
            “Un asesor de salud tomara contacto telefónico en un plazo máximo de
            24 horas hábiles”.
          </p>
        </div>
      </div>

      {adjuntos.length >= 1 && (
        <div className="row mb-4">
          <label
            className="col-sm-12 col-md-12 col-lg-3 form-label text-capitalize fw-bold"
            htmlFor="inputFile"
          ></label>
          <div className="col-sm-12 col-md-12 col-lg-6 border shadow border-primary p-3">
            <h5 className="">Por favor adjunte los siguientes archivos</h5>
            <ul>
              {adjuntos.map((requisito) => (
                <li key={requisito.idRequisitos}>{requisito.nombre}</li>
              ))}
            </ul>
            <p className="m-0">
              Usuario no cuenta con los requisitos anteriores?
            </p>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => {setRequisitos(!requisitos); setAlerta({}); setEmailRequisitos('')}}
            >
              <span className="text-primary m-0 fw-bold">
                Enviar requisitos por correo
              </span>
            </button>
            {requisitos && (
              <div className="row d-flex flex-column flex-sm-row">
                <div className="col col-sm-9">
                  <input
                    className="form-control"
                    type="email"
                    value={emailRequisitos}
                    onChange={handleChangeEmail}
                  />
                </div>
                <div className="col mt-2 mt-sm-0 d-grid">
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={correoValido}
                    onClick={enviarCorreo}
                  >
                    Enviar
                  </button>
                </div>
                {msg && <Mensajes alerta={alerta} />}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const Mensajes = ({ alerta }) => {
  return (
    <>
      <p className={`${alerta.error?"text-danger": "text-success" } text-center fw-bold py-1 my-2`}>{alerta.msg}</p>
    </>
  );
};

export default ListaRequisitos;

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { validador } from "../helpers/validaRut";
import useAppContext from "../hooks/useAppContext";
import InputText from "../components/InputText";
import {
  crearSolicitud,
  obtenerSolicitudesAnteriores,
} from "../helpers/fetchApi";
import { obtenerEdad, validaFecha } from "../helpers/validadores";
import AlertaWarning from "../components/AlertaWarning";
import ModalConfirmar from "../components/ModalConfirmar";
import ProgressBar from "../components/ProgressBar";
const FormdatosUser = () => {
  const navigate = useNavigate();
  const {
    listaRegiones,
    listaTipoIdentificacion,
    listaGenero,
    listaComunas,
    listaNacionalidad,
    datosPersona,
    getNombreRut,
    getListarComunas,
    objRequest,
    getDescripcionRegion,
    getDescripcionComuna,
    isLoading,
    cargarArchivo,
    archivosAdjuntos,
    confirmarSolicitud,
    listaNombreArchivos,
    setIsLoading,
    setConfirmacionTramite,
    confirmacionTramite,
    datosFormUsuario,
    setDatosFormUsuario,
    setDatosPersona,
    identificacion,
    setIdentificacion,
    tipoIdentificaion,
    setTipoIdentificacion,
  } = useAppContext();

  const {
    numeroIdentificacion,
    nacionalidad,
    nombre,
    apellidos,
    genero,
    fechaNacimiento,
    region,
    comuna,
    direccion,
    telefono1,
    telefono2,
    telefonoFijo,
    email,
  } = datosFormUsuario;

  //* VARIABLES DE ESTADO
  const [casosAnteriores, setCasosAnteriores] = useState([]);
  const [existePersona, setExistePersona] = useState("");
  const [bloquearCampo, setBloquearCampo] = useState(true);
  const [alerta, setAlerta] = useState(false);
  const [fechaValida, setFechaValida] = useState(false);
  const [errores, setErrores] = useState({});
  //* FIN VARIABLES DE ESTADO

  const fechaActual = new Date().toLocaleDateString("en-CA");

  //* LIMPIAR FORMULARIO
  const limpiarFormulario = () => {
    setErrores({});
    setDatosFormUsuario({
      ...datosFormUsuario,
      numeroIdentificacion: "",
      nacionalidad: "",
      nombre: "",
      apellidos: "",
      genero: "",
      fechaNacimiento: "",
      region: "",
      comuna: "",
      direccion: "",
      telefono1: "",
      telefono2: "",
      telefonoFijo: "",
      email: "",
    });
  };

  //* FUNCIONALIDAD MODAL
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {};
  const modalConfirmar = async () => {
    setShow(false);
    setIsLoading(true);
    let dataConfirmar = "";
    const getData = await crearSolicitud(objRequest);
    // console.log("PASO 1 -->", getData);
    if (getData.codigo === 0 && getData.numeroSolicitud) {
      setConfirmacionTramite({
        folio: getData.numeroSolicitud,
        correo: objRequest.email,
      });
      const pathArchivo = `${getData.numeroSolicitud}/1`;
      if (archivosAdjuntos.length > 0) {
        const dataArchivosAdj = await cargarArchivo(pathArchivo);
        if (dataArchivosAdj.codigo === 1) {
          dataConfirmar = await confirmarSolicitud(getData.numeroSolicitud);
          if (dataConfirmar.codigo === 0) {
            setIsLoading(false);
            navigate("tramite-finalizado");
          } else {
            setIsLoading(false);
            setAlerta({
              msg: "Ocurrio un error, por favor intente más tarde.",
              error: true,
            });
            setTimeout(() => {
              setAlerta({});
            }, 3500);
          }
        }
      } else {
        dataConfirmar = await confirmarSolicitud(getData.numeroSolicitud);
        if (dataConfirmar.codigo === 0) {
          setIsLoading(false);
          navigate("tramite-finalizado");
        } else {
          setIsLoading(false);
          setAlerta({
            msg: "Ocurrio un error, por favor intente más tarde.",
            error: true,
          });
          setTimeout(() => {
            setAlerta({});
          }, 3500);
        }
      }
    } else {
      setIsLoading(false);
      setAlerta({
        msg: "Ocurrio un error, por favor intente más tarde.",
        error: true,
      });
      setTimeout(() => {
        setAlerta({});
      }, 3500);
    }
  };
  //* FIN MODAL--------------------------------------------------------------

  //* RESETEA LOS INPUT NOMBRE Y APELLIDOS SI SE MODIFICA EL TIPO DE IDENTIFICACIÓN
  useEffect(() => {
    if (tipoIdentificaion) {
      setBloquearCampo(false);
    }
  }, []);

  const inputTipoIdentificacion = useRef();

  const onChangeTipoIdentificacion = () => {
    setTipoIdentificacion(inputTipoIdentificacion.current.value);

    if (inputTipoIdentificacion.current.value === "1") {
      setDatosPersona({});
      setIdentificacion("");
      setBloquearCampo(true);
      limpiarFormulario();
    }
    if (
      inputTipoIdentificacion.current.value === "2" ||
      inputTipoIdentificacion.current.value === "3"
    ) {
      setDatosPersona({});
      setIdentificacion("");
      setBloquearCampo(false);
      limpiarFormulario();
    }
  };
  //* LLENAR SELECT DE COMUNA------------------------------------------------------
  useEffect(() => {
    if (region) {
      getListarComunas(region);
    }
  }, [region]);

  //* MANEJADORES ----------------------------------------------------------------
  const handleChange = ({ target }) => {
    const { name, value } = target;
    setDatosFormUsuario({
      ...datosFormUsuario,
      [name]: value,
    });
    setErrores({ ...errores, [name]: "" });
  };

  const handleChangeRun = ({ target }) => {
    const { value } = target;
    setIdentificacion(value.toUpperCase());
    if (identificacion.length <= 9) {
      setDatosPersona({});
      setBloquearCampo(true);
      limpiarFormulario();
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!onValidarCampos()) return;
    setShow(true);
    //* REQUEST FORMULARIO---------------------------------------------------
    objRequest.codTipoIdentificacion = tipoIdentificaion;
    objRequest.numeroIdentificacion = numeroIdentificacion;
    objRequest.UsuarioCrea = `${nombre.split(" ")[0]} ${
      apellidos.split(" ")[0]
    }`;
    objRequest.rutUsuarioCrea = identificacion;
    objRequest.nacionalidad = nacionalidad;
    objRequest.nombre = nombre;
    objRequest.apellidos = apellidos;
    objRequest.genero = genero;
    objRequest.edad = obtenerEdad(fechaNacimiento);
    objRequest.fechaNacimiento = fechaNacimiento;
    objRequest.region = region;
    objRequest.nombreRegion = getDescripcionRegion(listaRegiones, region);
    objRequest.comuna = comuna;
    objRequest.nombreComuna = getDescripcionComuna(listaComunas, comuna);
    objRequest.direccion = direccion;
    objRequest.telefono1 = telefono1;
    objRequest.telefono2 = telefono2;
    objRequest.telefonoFijo = telefonoFijo;
    objRequest.email = email;

    const dataSolicitudesAnt = await obtenerSolicitudesAnteriores(objRequest);
    if (
      dataSolicitudesAnt.codigo === 0 &&
      dataSolicitudesAnt.totalRegistros >= 0
    ) {
      if (dataSolicitudesAnt.totalRegistros >= 0) {
        setCasosAnteriores(dataSolicitudesAnt);
      }
    } else {
      setCasosAnteriores([]);
    }
  };
  //* VERIFICAR RUN VALIDO---------------------------------------------------
  const rutValido = validador(identificacion);

  useEffect(() => {
    if (!rutValido && identificacion.length > 9) {
      setErrores({
        ...errores,
        identificacion: "El RUN ingresado no es válido",
      });
    } else {
      setErrores({ ...errores, identificacion: "" });
    }
  }, [identificacion]);

  //* VERIFICA SI EXISTE UNA PERSONA EN OIPA
  const verificaRun = async () => {
    if (rutValido && identificacion.length > 4) {
      const objRun = { run: identificacion };
      const existePersonaRut = await getNombreRut(objRun);
      if (existePersonaRut.codigo === 0) {
        setBloquearCampo(false);
        setExistePersona(true);
      } else {
        setBloquearCampo(false);
        setExistePersona(false);
      }
    }
  };

  //** MODIFICA LOS INPUT NOMBRE Y APELLIDO CON LOS DATOS DEL RUT------------
  const apellidosUsuario =
    datosPersona.primerApellido !== null
      ? `${datosPersona.primerApellido} ${datosPersona.segundoApellido}`
      : "";
  useEffect(() => {
    if (Object.entries(datosPersona).length) {
      setDatosFormUsuario({
        ...datosFormUsuario,
        nombre: datosPersona.nombres,
        apellidos: apellidosUsuario,
      });
    }
  }, [datosPersona]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This adds a smooth scrolling effect
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

//* VALIDACIONES DEL FORMULARIO----------------------------------------------
  const onValidarCampos = () => {
    const erroresInput = {};
    if ((tipoIdentificaion === '2' || tipoIdentificaion === '3') && !numeroIdentificacion.trim()) {
      erroresInput.numeroIdentificacion = "Este campo es obligatorio";
    }
    if (!nacionalidad.trim()) {
      erroresInput.nacionalidad = "Este campo es obligatorio";
    }
    if (!nombre.trim()) {
      erroresInput.nombre = "Este campo es obligatorio";
    }
    if (!apellidos.trim()) {
      erroresInput.apellidos = "Este campo es obligatorio";
    }
    if (!genero.trim()) {
      erroresInput.genero = "Este campo es obligatorio";
    }
    if (!fechaNacimiento.trim()) {
      erroresInput.fechaNacimiento = "Este campo es obligatorio";
    }
    if (!region.trim()) {
      erroresInput.region = "Este campo es obligatorio";
    }
    if (!comuna.trim()) {
      erroresInput.comuna = "Este campo es obligatorio";
    }
    if (!direccion.trim()) {
      erroresInput.direccion = "Este campo es obligatorio";
    } else if (direccion.length < 4) {
      erroresInput.direccion = "Este campo debe tener mínimo 3 caracteres";
    }
    if (!telefono1.trim()) {
      erroresInput.telefono1 = "Este campo es obligatorio";
    } else if (telefono1.length > 1 && !/^\d{7,10}$/.test(telefono1)) {
      erroresInput.telefono1 = "El teléfono ingresado es incorrecto";
    }
    if (telefono2.length > 1 && !/^\d{7,10}$/.test(telefono2)) {
      erroresInput.telefono2 = "El teléfono ingresado es incorrecto";
    }
    if (telefonoFijo.length > 1 && !/^\d{7,10}$/.test(telefonoFijo)) {
      erroresInput.telefonoFijo = "El teléfono ingresado es incorrecto";
    }
    if (!email.trim()) {
      erroresInput.email = "Este campo es obligatorio";
    } else if (
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)
    ) {
      erroresInput.email = "Formato de correo invalido";
    }
    setErrores(erroresInput);
    return Object.keys(erroresInput).length === 0;
  };
  //* FIN VALIDACIONES FORMULARIO--------------------------------------------
  //* VALIDAR SI ES MENOR DE EDAD Y FECHA VALIDA-----------------------------
  useEffect(() => {
    const fecha = validaFecha(fechaNacimiento);
    if (!fecha && fecha !== undefined) {
      // console.log("FECHA MALA");
      setFechaValida(true);
    } else {
      setFechaValida(false);
    }

    const edad = obtenerEdad(fechaNacimiento);
    if (fechaNacimiento) {
      setDatosFormUsuario({ ...datosFormUsuario, edadSolicitante: edad });
    }
    if (edad < 18) {
      // console.log("ERES MENOR SAPO");
      setAlerta({
        msg: "Solicitante no es mayor de 18 años, por lo cual no puede realizar este tramite",
        error: true,
      });
      return;
    }
    setAlerta(false);
  }, [fechaNacimiento]);

  const { msg } = alerta;
  const steps = [
    "Tipo Solicitud",
    "Datos Solicitante y Afectado",
    "Comprobante de envío",
  ];
  const currentStep = 1;

  return (
    <div className="container">
      <ProgressBar steps={steps} currentStep={currentStep} />
      <ModalConfirmar
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        modalConfirmar={modalConfirmar}
        casosAnteriores={casosAnteriores}
      />

      <hr />
      <div className="row justify-content-center mt-3">
        <div className="col">
          <h3>Datos Solicitud</h3>
          <p>
            Por favor complete los datos a continuación asociados al tipo de
            solicitud a realizar
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row justify-content-center">
            <div className="border p-3">
              <div className="row p-3">
                {/* BLOQUE 1 */}
                <div className="col-sm-12 col-lg-6">
                  <div className="col-12 col-sm-12  d-flex flex-column flex-md-row  pe-4 mb-3 ">
                    <label
                      className="form-label col-sm-5 col-md-6 col-lg-5 fw-bold"
                      htmlFor="tipoIdentificacion"
                    >
                      Tipo identificacion:{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-12 col-md-6 col-lg-7">
                      <select
                        ref={inputTipoIdentificacion}
                        className="form-select shadow-sm"
                        id="tipoIdentificacion"
                        name="tipoIdentificaion"
                        onChange={onChangeTipoIdentificacion}
                        value={tipoIdentificaion}
                      >
                        <option value="">Seleccione</option>
                        {listaTipoIdentificacion.map((opcion) => (
                          <option
                            key={opcion.idIdentificacion}
                            value={opcion.idIdentificacion}
                          >
                            {opcion.descripcion}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {tipoIdentificaion === "1" && (
                    <div className="col-12 col-sm-12  d-flex flex-column flex-md-row  pe-4 mb-3">
                      <label
                        className="form-label col-sm-5 col-md-6 col-lg-5 fw-bold"
                        htmlFor="identificacion"
                      >
                        RUN: <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-12 col-md-6 col-lg-7">
                        <input
                          type="text"
                          className="form-control shadow-sm"
                          name="identificacion"
                          value={identificacion}
                          onChange={handleChangeRun}
                          onKeyUp={verificaRun}
                          id="identificacion"
                          placeholder="RUT Ejem.: 1234567-9"
                        />
                        {errores.identificacion && (
                          <p className="text-danger mt-2">
                            {errores.identificacion}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                  {(tipoIdentificaion === "2" || tipoIdentificaion === "3") && (
                    <InputText
                      type={"text"}
                      clase={"form-control shadow-sm text-uppercase"}
                      label={"N° Identificación"}
                      htmlFor={"numeroIdentificacion"}
                      name={"numeroIdentificacion"}
                      id={"numeroIdentificacion"}
                      maxLength={"12"}
                      obligatorio={true}
                      errorsMessage={errores.numeroIdentificacion}
                      disabled={bloquearCampo}
                      placeholder={"N° Identificación"}
                      onChange={handleChange}
                      value={numeroIdentificacion}
                    />
                  )}
                  <div className="col-12 col-sm-12  d-flex flex-column flex-md-row  pe-4 mb-3">
                    <label
                      className="form-label col-sm-5 col-md-6 col-lg-5 fw-bold"
                      htmlFor="nacionalidad"
                    >
                      Nacionalidad: <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-12 col-md-6 col-lg-7">
                      <select
                        className="form-select shadow-sm text-uppercase"
                        id="nacionalidad"
                        name="nacionalidad"
                        disabled={bloquearCampo}
                        onChange={handleChange}
                        value={nacionalidad}
                      >
                        <option value="">Seleccione</option>
                        {listaNacionalidad.map((opcion) => (
                          <option
                            key={opcion.codigoPrincipal}
                            value={opcion.codigoPrincipal}
                          >
                            {opcion.descripcion.toUpperCase()}
                          </option>
                        ))}
                      </select>
                      {errores.nacionalidad && (
                        <div className="mt-1">
                          <span className="text-danger">
                            {errores.nacionalidad}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <InputText
                    type={"text"}
                    clase={"form-control shadow-sm text-uppercase"}
                    label={"Nombres"}
                    htmlFor={"nombre"}
                    name={"nombre"}
                    id={"nombre"}
                    maxLength={"50"}
                    obligatorio={true}
                    errorsMessage={errores.nombre}
                    disabled={bloquearCampo}
                    placeholder={"Nombres"}
                    onChange={handleChange}
                    value={nombre}
                  />
                  <InputText
                    type={"text"}
                    clase={"form-control shadow-sm text-uppercase"}
                    label={"Apellidos"}
                    htmlFor={"apellidos"}
                    name={"apellidos"}
                    id={"apellidos"}
                    maxLength={"50"}
                    obligatorio={true}
                    errorsMessage={errores.apellidos}
                    disabled={bloquearCampo}
                    placeholder={"Apellidos"}
                    onChange={handleChange}
                    value={apellidos}
                  />
                  <div className="col-12 col-sm-12  d-flex flex-column flex-md-row  pe-4 mb-3">
                    <label
                      className="form-label col-sm-5 col-md-6 col-lg-5 fw-bold"
                      htmlFor="genero"
                    >
                      Genero: <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-12 col-md-6 col-lg-7">
                      <select
                        className="form-select shadow-sm text-uppercase"
                        id="genero"
                        name="genero"
                        disabled={bloquearCampo}
                        onChange={handleChange}
                        value={genero}
                      >
                        <option value="">Seleccione</option>
                        {listaGenero.map((opcion) => (
                          <option
                            key={opcion.codigoPrincipal}
                            value={opcion.codigoPrincipal}
                          >
                            {opcion.descripcion}
                          </option>
                        ))}
                      </select>
                      {errores.genero && (
                        <div className="mt-1">
                          <span className="text-danger">{errores.genero}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12  d-flex flex-column flex-md-row  pe-4 mb-3">
                    <label
                      className="form-label col-sm-5 col-md-6 col-lg-5 fw-bold"
                      htmlFor="fechaNacimiento"
                    >
                      Fecha Nacimiento: <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-12 col-md-6 col-lg-7">
                      <input
                        type="date"
                        max={fechaActual}
                        className="form-control shadow-sm text-uppercase"
                        name="fechaNacimiento"
                        id="fechaNacimiento"
                        disabled={bloquearCampo}
                        onChange={handleChange}
                        value={fechaNacimiento}
                      />
                      {errores.fechaNacimiento && (
                        <div className="mt-1">
                          <span className="text-danger">
                            {errores.fechaNacimiento}
                          </span>
                        </div>
                      )}
                      {fechaValida && (
                        <div className="mt-1">
                          <span className="text-danger">
                            Fecha no permitida o fotmato inválido, Ejem.:
                            01/09/2006
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-sm-12  d-flex flex-column flex-md-row  pe-4 mb-3">
                    <label
                      className="form-label col-sm-5 col-md-6 col-lg-5 fw-bold"
                      htmlFor="region"
                    >
                      Región: <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-12 col-md-6 col-lg-7">
                      <select
                        className="form-select shadow-sm text-uppercase"
                        id="region"
                        name="region"
                        disabled={bloquearCampo}
                        onChange={handleChange}
                        value={region}
                      >
                        <option value="">Seleccione</option>
                        {listaRegiones.map((opcion) => (
                          <option
                            key={opcion.codigoPrincipal}
                            value={opcion.codigoPrincipal}
                          >
                            {opcion.descripcion}
                          </option>
                        ))}
                      </select>
                      {errores.region && (
                        <div className="mt-1">
                          <span className="text-danger">{errores.region}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* BLOQUE  2*/}
                <div className="col-sm-12 col-lg-6">
                  <div className="col-12 col-sm-12  d-flex flex-column flex-md-row  pe-4 mb-3">
                    <label
                      className="form-label col-sm-5 col-md-6 col-lg-5 fw-bold"
                      htmlFor="comuna"
                    >
                      Comuna: <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-12 col-md-6 col-lg-7">
                      <select
                        className="form-select shadow-sm text-uppercase"
                        id="comuna"
                        name="comuna"
                        disabled={bloquearCampo}
                        onChange={handleChange}
                        value={comuna}
                      >
                        <option value="">Seleccione</option>
                        {listaComunas.map((opcion) => (
                          <option
                            key={opcion.codigoPrincipal}
                            value={opcion.codigoPrincipal}
                          >
                            {opcion.descripcion}
                          </option>
                        ))}
                      </select>
                      {errores.comuna && (
                        <div className="mt-1">
                          <span className="text-danger">{errores.comuna}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <InputText
                    type={"text"}
                    clase={"form-control shadow-sm text-uppercase"}
                    label={"Dirección"}
                    htmlFor={"direccion"}
                    name={"direccion"}
                    id={"direccion"}
                    maxLength={"50"}
                    obligatorio={true}
                    errorsMessage={errores.direccion}
                    disabled={bloquearCampo}
                    placeholder={"Dirección. Ejem.: Costa 27"}
                    onChange={handleChange}
                    value={direccion}
                  />
                  <InputText
                    type={"text"}
                    clase={"form-control shadow-sm"}
                    label={"Teléfono 1"}
                    htmlFor={"telefono1"}
                    name={"telefono1"}
                    id={"telefono1"}
                    maxLength={"10"}
                    obligatorio={true}
                    errorsMessage={errores.telefono1}
                    disabled={bloquearCampo}
                    placeholder="Telefóno móvil Ejem.: 92345445"
                    onChange={handleChange}
                    value={telefono1}
                  />
                  <InputText
                    type={"text"}
                    clase={"form-control shadow-sm"}
                    label={"Teléfono 2"}
                    htmlFor={"telefono2"}
                    name={"telefono2"}
                    id={"telefono2"}
                    maxLength={"10"}
                    obligatorio={false}
                    errorsMessage={errores.telefono2}
                    disabled={bloquearCampo}
                    placeholder="Telefóno móvil Ejem.: 92345445"
                    onChange={handleChange}
                    value={telefono2}
                  />
                  <InputText
                    type={"text"}
                    clase={"form-control shadow-sm"}
                    label={"Teléfono Fijo"}
                    htmlFor={"telefonoFijo"}
                    name={"telefonoFijo"}
                    id={"telefonoFijo"}
                    maxLength={"10"}
                    obligatorio={false}
                    errorsMessage={errores.telefonoFijo}
                    disabled={bloquearCampo}
                    placeholder="Telefóno móvil Ejem.: 92345445"
                    onChange={handleChange}
                    value={telefonoFijo}
                  />
                  <InputText
                    type={"text"}
                    clase={"form-control shadow-sm"}
                    label={"Email"}
                    htmlFor={"email"}
                    name={"email"}
                    id={"email"}
                    maxLength={"150"}
                    obligatorio={true}
                    errorsMessage={errores.email}
                    disabled={bloquearCampo}
                    placeholder="Email. Ejem.: correo@correo.com"
                    onChange={handleChange}
                    value={email}
                  />
                </div>
                {msg && <AlertaWarning alerta={alerta} />}
              </div>
            </div>
          </div>

          <p className="text-danger fw-bold mt-3">* Datos obligatorios</p>
          <div className="row justify-content-center">
            <div className="col text-center mb-4">
              <Link to={"/"} className="btn btn-secondary me-2 shadow">
                Volver
              </Link>
              <Button
                className="shadow"
                variant="primary"
                onClick={handleShow}
                type="submit"
                disabled={bloquearCampo || isLoading || fechaValida}
              >
                Crear solicitud
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default FormdatosUser;

export const fetchApi = async (url, body = null) => {
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      apikey: process.env.REACT_APP_APY_KEY,
    },
    body: JSON.stringify(body),
  };
  try {
    const resp = await fetch(url, request);
    const data = await resp.json();
    return data;
  } catch (error) {
    // console.log(error);
    return error
  }
};
export const fetchCargarArchivo = async (url, body = null) => {
  const request = {
    method: "POST",
    headers: {
      apikey: process.env.REACT_APP_APY_KEY,
    },
    body: body,
  };
  try {
    const resp = await fetch(url, request);
    const data = await resp.json();
    return data;
  } catch (error) {
    // console.log(error);
    return error
  }
};

export const obtenerSolicitudesAnteriores = async (objRequest) => {
  const body = {
    runUsuarioCreacion: objRequest.rutUsuarioCrea,
    nomUsuarioCreacion: objRequest.UsuarioCrea,
    canal: "ATENCION_QR",
    // tipoTipificacion: 1198,
    tipoTipificacion: objRequest.tipoTipificacion,
    runAfectado: objRequest.rutUsuarioCrea,
    runSolicitante: objRequest.rutUsuarioCrea,
    fechaCreacion: new Date()
  };

  const URL_OBTENER_SOLICITUDES_ANT = process.env.REACT_APP_WS_SOLICITUDES_CIUDAD_OBTENER_SOLICITUDES_ANT;

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      apikey: process.env.REACT_APP_APY_KEY,
    },
    body: JSON.stringify(body),
  };
  try {
    const resp = await fetch(URL_OBTENER_SOLICITUDES_ANT, request);
    const data = await resp.json();
    return data;
  } catch (error) {
    // console.log(error);
    return error
  }
};

export const crearSolicitud = async (objRequest) => {
  const body = {
    fechaIngresoInstitucion: new Date(),
    canal: "ATENCION_QR",
    UsuarioCrea: objRequest.UsuarioCrea,
    rutUsuarioCrea: objRequest.rutUsuarioCrea,
    nombreUsuarioCrea: `${objRequest.nombre} ${objRequest.apellidos}`,
    rolUsuarioCrea: null,
    codSucursalUsuarioCrea: null,
    nombreSucursalUsuarioCrea: null,
    idTipoSolicitud: objRequest.tipoSolicitud,
    idSubtipoSolicitud: objRequest.tipificacion,
    tipoTipificacion: objRequest.tipoTipificacion,
    descripcionSolicitud: objRequest.descripcionSolicitud,
    solicitudConcreta: null,
    aseguradoMismoAfectado: 1,
    solicitante: [
      {
        codTipoIdentificacion: objRequest.codTipoIdentificacion,
        numeroIdentificacion: objRequest.numeroIdentificacion,
        rut: objRequest.rutUsuarioCrea,
        nacionalidad: objRequest.nacionalidad,
        nombres: objRequest.nombre,
        apellidos: objRequest.apellidos,
        nombreSocial: null,
        genero: objRequest.genero,
        generoSocial: null,
        edad: objRequest.edad,
        fechaNacimiento: objRequest.fechaNacimiento,
        Codregion: objRequest.region,
        nomRegion: objRequest.nombreRegion,
        Codcomuna: objRequest.comuna,
        NomComuna: objRequest.nombreComuna,
        direccion: objRequest.direccion,
        telefonoMovil: objRequest.telefono1,
        telefonoMovilDos: objRequest.telefono2,
        telefonoFijo: objRequest.telefonoFijo,
        email: objRequest.email,
        tramo: null,
        tipoCotizante: {},
        caraterizacion: {},
        estado: {},
        marcas: [],
      },
    ],
    afectado: [
      {
        codTipoIdentificacion: objRequest.codTipoIdentificacion,
        numeroIdentificacion: objRequest.numeroIdentificacion,
        rut: objRequest.rutUsuarioCrea,
        nacionalidad: objRequest.nacionalidad,
        nombres: objRequest.nombre,
        apellidos: objRequest.apellidos,
        nombreSocial: null,
        genero: objRequest.genero,
        generoSocial: null,
        edad: objRequest.edad,
        fechaNacimiento: objRequest.fechaNacimiento,
        Codregion: objRequest.region,
        nomRegion: objRequest.nombreRegion,
        Codcomuna: objRequest.comuna,
        NomComuna: objRequest.nombreComuna,
        direccion: objRequest.direccion,
        telefonoMovil: objRequest.telefono1,
        telefonoMovilDos: objRequest.telefono2,
        telefonoFijo: objRequest.telefonoFijo,
        email: objRequest.email,
        tramo: null,
        tipoCotizante: {},
        caraterizacion: {},
        estado: {},
        marcas: [],
      },
    ],
    baseConocimiento: 0,
    casoEscala: objRequest.tipoTipificacionAux.esCasoEscalable,
    informacionAdicional: {
      nombrePrestador: null,
      fechaProblema: null,
      canalAtencion: null,
      fechaSolicitudConvenio: null,
      nombrePrestadorConvenio: null,
      rutPrestador: null,
      nombreFarmacia: null,
      direccionFarmacia: null,
      nombrePrestacion: null,
      nombrePrestadorIndicaMedicamento: null,
      fechaIndicacionMedicamento: null,
      numeroLicenciaMedica: null,
      nombreProblemaSalud: null,
      fechaVencimientoGarantia: null,
      nombreLugarAtencion: null,
      fechaAtencion: null,
      fechaIngresoAlPrestador: null,
      numeroRolArbitral: null,
      nombreEmpleador: null,
      nombreInstitucion: null,
      codigoRegionPrestador: null,
      nombreRegionPrestador: null,
    },
    tipoTipificacionAux: objRequest.tipoTipificacionAux,
    // "editable": { "solicitante": 1, "afectado": 0 },
    // "oipa": { "solicitante": 1, "afectado": 1 },
    devolucion: [
      {
        tipoDevolucion: null,
        opcionDevolucion: null,
        nombreOpcionDevolucion: null,
        mesInicioPeriodo: null,
        anioInicioPeriodo: null,
        mesFinPeriodo: null,
        anioFinPeriodo: null,
        banco: null,
        tipoCuenta: null,
        cuenta: null,
      },
    ],
    tipoUsuario: objRequest.tipoUsuario,
  };
  const URL_CREAR_SOLICITUD = process.env.REACT_APP_WS_SOLICITUDES_CIUDAD_CREAR_SOLICITUD_CIUDAD;

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      apikey: process.env.REACT_APP_APY_KEY,
    },
    body: JSON.stringify(body),
  };
  try {
    const resp = await fetch(URL_CREAR_SOLICITUD, request);
    const data = await resp.json();
    return data;
  } catch (error) {
    // console.log(error);
    return error
  }
};

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InputFile from "../components/InputFile";
import useAppContext from "../hooks/useAppContext";
import Spinner from "../components/Spinner";
import ProgressBar from "../components/ProgressBar";

const Tramites = () => {
  const [statusArchivos, setStatusArchivos] = useState(null);

  const {
    tipoUsuario,
    tipoSolicitud,
    tipificacion,
    tipoTipificacion,
    desplegarRquisitos,
    isLoading,
    getListarRequisitos,
    objTramites,
    setObjTramites,
  } = useAppContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (objTramites.codTipoTipificacion) {
      getListarRequisitos(objTramites.codTipoTipificacion);
    }
  }, [objTramites.codTipoTipificacion]);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setObjTramites({
      ...objTramites,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/form-datos-usuario");
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This adds a smooth scrolling effect
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const steps = ["Tipo Solicitud", "Datos Solicitante y Afectado", "Comprobante de envío"];
  const currentStep = 0;
  return (
    <>
      <ProgressBar steps={steps} currentStep={currentStep} />
      <div className="container">
        <hr />
        <div className="row justify-content-center">
          <div className="col">
            <h3>Datos Solicitud</h3>
            <p>
              Por favor complete los datos a continuación asociados al tipo de
              solicitud a realizar
            </p>
          </div>

          {/* FORMULARIO */}
          <form onSubmit={handleSubmit}>
            <div className="col-12 border p-4">
              <div className="row mb-4">
                <label
                  className="col-sm-12 col-md-12 col-lg-3 form-label text-capitalize fw-bold"
                  htmlFor="tipoUsuario"
                >
                  Tipo Usuario: <span className="text-danger">*</span>
                </label>
                <div className="col-sm-12 col-md-12 col-lg-7">
                  <select
                    className="form-select shadow-sm"
                    id="tipoUsuario"
                    disabled
                  >
                    {
                      <option value={tipoUsuario.idTipoUsuario}>
                        {tipoUsuario.nombreTipoUsuario}
                      </option>
                    }
                  </select>
                </div>
              </div>
              <div className="row mb-4">
                <label
                  className="col-sm-12 col-md-12 col-lg-3 form-label text-capitalize fw-bold"
                  htmlFor="tipoSolicitud"
                >
                  Tipo: <span className="text-danger">*</span>
                </label>
                <div className="col-sm-12 col-md-12 col-lg-7">
                  <select
                    className="form-select shadow-sm"
                    id="tipoSolicitud"
                    disabled
                  >
                    {
                      <option value={tipoSolicitud.idTipoSolicitud}>
                        {tipoSolicitud.descripcion}
                      </option>
                    }
                  </select>
                </div>
              </div>
              <div className="row mb-4">
                <label
                  className="col-sm-12 col-md-12 col-lg-3 form-label text-capitalize fw-bold"
                  htmlFor="concepto"
                >
                  Concepto: <span className="text-danger">*</span>
                </label>
                <div className="col-sm-12 col-md-12 col-lg-7">
                  <select
                    className="form-select shadow-sm"
                    id="concepto"
                    disabled
                  >
                    {
                      <option value={tipificacion.idSubTipoSolicitud}>
                        {tipificacion.descripcionBeneficiario}
                      </option>
                    }
                  </select>
                </div>
              </div>
              <div className="row mb-4">
                <label
                  className="col-sm-12 col-md-12 col-lg-3 form-label text-capitalize fw-bold"
                  htmlFor="detalle"
                >
                  Detalle: <span className="text-danger">*</span>
                </label>
                <div className="col-sm-12 col-md-12 col-lg-7">
                  <select
                    className="form-select shadow-sm"
                    onChange={handleChange}
                    name="codTipoTipificacion"
                    id="detalle"
                    value={objTramites?.codTipoTipificacion}
                  >
                    <option value="">--Seleccione--</option>
                    {tipoTipificacion.listado
                      ? tipoTipificacion?.listado.map((opcion) => (
                          <option
                            key={opcion.idDetalleSolicitud}
                            value={opcion.idDetalleSolicitud}
                          >
                            {opcion.descripcion}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
              </div>
              {objTramites.codTipoTipificacion && desplegarRquisitos === 1 ? (
                <div className="row">
                  <label
                    className="col-sm-12 col-md-12 col-lg-3 fw-bold"
                    htmlFor="descripcion"
                  >
                    Descripción:
                  </label>
                  <div className="col-sm-12 col-md-12 col-lg-7">
                    <textarea
                      className="form-control shadow-sm"
                      id="descripcion"
                      name="descripcion"
                      onChange={handleChange}
                      value={objTramites.descripcion}
                    ></textarea>
                  </div>
                </div>
              ) : null}
            </div>
            {objTramites.codTipoTipificacion && desplegarRquisitos === 1 ? (
              <>
                <div className="col-12 mt-4">
                  <h3>Requisitos o Información Adicional</h3>
                  <p>
                    Por favor complete los datos a continuación asociados al
                    tipo de solicitud a realizar
                  </p>
                </div>
                <div className="col-12 border p-4">
                  <InputFile setStatusArchivos={setStatusArchivos} />
                </div>
              </>
            ) : null}
            <p className="text-danger fw-bold mt-3">* Datos obligatorios</p>
            <div className="row justify-content-center">
              <div className="col text-center mb-4">
                <button
                  className="btn btn-primary me-2 shadow"
                  disabled={
                    !objTramites.codTipoTipificacion ||
                    statusArchivos ||
                    isLoading
                  }
                >
                  Continuar
                </button>
              </div>
            </div>
          </form>
        </div>

        {/* FIN FORMULARIO */}
      </div>
    </>
  );
};

export default Tramites;

import { Outlet, Navigate } from "react-router-dom";
import useAppContext from "../hooks/useAppContext";
import Banner from '../components/Banner'
import Spinner from "../components/Spinner";

const RutaProtegida = () => {
    const { objTramites, isLoading } = useAppContext();
  return (
    <>
    {isLoading && <Spinner />}
      <Banner />
      {objTramites.codTipoTipificacion ? <Outlet/> : <Navigate to="/"/>}
    </>
  );
};

export default RutaProtegida;

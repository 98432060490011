export const obtenerEdad = (dateString) => {
  let hoy = new Date();
  let fechaNacimiento = new Date(dateString);
  let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
  let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
  if (
    diferenciaMeses < 0 ||
    (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
  ) {
    edad--;
  }
  return edad;
};

export const validaFecha = (fecha) => {
  if (!fecha) return;
  const nuevaFecha = fecha.split("-");
  let anio = nuevaFecha[0];
  let mes = nuevaFecha[1];
  let dia = nuevaFecha[2];
  anio = parseInt(anio, 10);
  mes = parseInt(mes, 10) - 1;
  dia = parseInt(dia, 10);
  if (anio < 1900 || anio > 2100) return false;
  let fechaAnio = new Date(anio, 1, 1);
  let fechaDate = new Date(anio, mes, dia);
  return fechaAnio.getFullYear() == fechaDate.getFullYear() &&
    mes == fechaDate.getMonth()
    ? true
    : false;
};

